import React, { useState, useEffect } from 'react';
import { Menu, X } from 'lucide-react';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const navItems = [];

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrolled]);

  return (
    <nav className={`fixed w-full z-50 transition-all duration-300 bg-white shadow-lg`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href="/" className="flex items-center">
              <span className="sr-only">TrojanVectors</span>
              <img src="/images/img.png" alt="TrojanVectors Logo" className="h-10 w-10" />
              <span className="ml-2 text-xl font-bold text-gray-900">
                TrojanVectors
              </span>
            </a>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <button
              type="button"
              className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
              onClick={() => setIsOpen(!isOpen)}
            >
              <span className="sr-only">Open menu</span>
              <Menu className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden md:flex items-center justify-end space-x-8">
            {navItems.map((item) => (
              <a
                key={item}
                href={`#${item.toLowerCase().replace(' ', '-')}`}
                className="text-lg font-medium text-gray-500 hover:text-gray-900 transition duration-300 ease-in-out hover:bg-gray-100 px-3 py-2 rounded-md"
              >
                {item}
              </a>
            ))}
            <a
              href="https://cal.com/sachdh/15min"
              target="_blank"
              rel="noopener noreferrer"
              className="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
            >
              Book a Demo
            </a>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div className={`${isOpen ? 'block' : 'hidden'} md:hidden`}>
        <div className="fixed inset-0 z-50 bg-gray-600 bg-opacity-75" aria-hidden="true"></div>
        <div className="fixed inset-y-0 right-0 z-50 w-full bg-white shadow-xl p-6 transition transform origin-right">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <img src="/images/img.png" alt="TrojanVectors Logo" className="h-8 w-8" />
              <span className="ml-2 text-xl font-bold text-gray-900">TrojanVectors</span>
            </div>
            <button
              type="button"
              className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
              onClick={() => setIsOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <X className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6">
            <nav className="grid gap-y-8">
              {navItems.map((item) => (
                <a
                  key={item}
                  href={`#${item.toLowerCase().replace(' ', '-')}`}
                  className="text-lg font-medium text-gray-900 hover:text-gray-700 hover:bg-gray-100 px-3 py-2 rounded-md transition duration-300 ease-in-out"
                  onClick={() => setIsOpen(false)}
                >
                  {item}
                </a>
              ))}
            </nav>
          </div>
          <div className="mt-6 p-5">
            <a
              href="https://cal.com/sachdh/15min"
              target="_blank"
              rel="noopener noreferrer"
              className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-blue-600 hover:bg-blue-700 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105"
              onClick={() => setIsOpen(false)}
            >
              Book a Demo
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;