import React from 'react';
import { Linkedin, Twitter } from 'lucide-react';

const AboutUs = () => {
  return (
    <section id="about-us" className="bg-gradient-to-br from-blue-50 via-sky-50 to-indigo-100 py-16 px-4 md:px-8">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-3xl font-bold text-center mb-8 text-gray-800">About Us</h2>

        <p className="text-lg text-center mb-4 text-gray-700">
          We're AI researchers working hard to enable building world-changing AI products by securing them against real risks. Our goal is to accelerate progress by solving complex security challenges to enable our customers build ambitious products. If you are an ambitious techno-optimist, <a href="mailto:contact@trojanvectors.com?subject=Joining%20Trojan%20Vectors" target='_blank' rel='noopener noreferrer' className="text-gray-500 hover:text-blue-500">join us</a> to shape the future of AI products.
        </p>


        <div className="bg-white bg-opacity-90 backdrop-blur-sm rounded-lg shadow-lg overflow-hidden">
          <div className="md:flex items-center">
            <div className="md:w-1/3 lg:w-1/4 flex-shrink-0 p-8 flex justify-center">
              <div className="w-64 h-64 rounded-full border-4 border-white shadow-2xl overflow-hidden">
                <img
                  src="/images/sachin2.jpg"
                  alt="Sachin Dharashivkar"
                  className="w-full h-full object-cover"
                  style={{
                    imageRendering: 'auto',
                    filter: 'brightness(110%) contrast(110%)'
                  }}
                  onLoad={(e) => {
                    e.target.style.imageRendering = 'auto';
                  }}
                />
              </div>
            </div>
            <div className="p-8 md:w-2/3 lg:w-3/4">
              <div className="uppercase tracking-wide text-sm text-blue-600 font-semibold">Founder</div>
              <h3 className="mt-1 text-2xl leading-tight font-medium text-gray-900">Sachin Dharashivkar</h3>
              <p className="mt-2 text-gray-600">Masters in Computer Science, UMass Amherst</p>
              <ul className="mt-4 text-gray-700">
                <li className="flex items-start mb-2">
                  <svg className="w-4 h-4 mr-2 text-blue-500 flex-shrink-0 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  <span>Solving real-world problems with deep reinforcement learning agents since 2016</span>
                </li>
                <li className="flex items-start mb-2">
                  <svg className="w-4 h-4 mr-2 text-blue-500 flex-shrink-0 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  <span>Built simulators and agents to execute high volume equity trades at JPMorgan Chase</span>
                </li>
                <li className="flex items-start">
                  <svg className="w-4 h-4 mr-2 text-blue-500 flex-shrink-0 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path></svg>
                  <span>Trained RL agents to play collaborative, multiplayer games at Unity Technologies</span>
                </li>
              </ul>
              <div className="mt-6 flex space-x-4">
                <a href="https://www.linkedin.com/in/sachin-dharashivkar/" target='_blank' rel='noopener noreferrer' className="text-gray-400 hover:text-blue-500">
                  <span className="sr-only">LinkedIn</span>
                  <Linkedin className="h-6 w-6" />
                </a>
                <a href="https://twitter.com/sachdh" target='_blank' rel='noopener noreferrer' className="text-gray-400 hover:text-blue-500">
                  <span className="sr-only">Twitter</span>
                  <Twitter className="h-6 w-6" />
                </a>
                <a href="https://sachindharashivkar.substack.com/" target='_blank' rel='noopener noreferrer' className="text-gray-400 hover:text-blue-500">
                  <span className="sr-only">Substack</span>
                  <svg className="h-6 w-6" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M22.539 8.242H1.46V5.406h21.08v2.836zM1.46 10.812V24L12 18.11 22.54 24V10.812H1.46zM22.54 0H1.46v2.836h21.08V0z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;