import React, { useState } from 'react';
import { ChevronRight } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const talks = [
  {
    title: "Indirect Prompt Injections with Notion AI",
    subtitle: "Learn how poisoned documents in Notion workspaces can corrupt Notion AI",
    link: "https://sachindharashivkar.substack.com/p/indirect-prompt-injection-with-notion", // Replace with the actual link when available
    logo: "/images/notionhq_logo.jpg" // Replace with actual Notion logo path
  },
  {
    title: "RAG Security: Indirect Prompt Injections",
    subtitle: "Explore advanced RAG security techniques presented at Zoom's AI Builders event",
    link: "https://www.youtube.com/watch?v=BHqB1eC1i_o",
    logo: "/images/zoom.jpg" // Replace with actual Zoom logo path
  },
  {
    title: "Securing your RAG from Prompt Injection Attacks",
    subtitle: "RAG protection strategies at Qdrant's Vector Search Office Hours",
    link: "https://www.linkedin.com/events/securingyourragfrompromptinject7227015643311521792/",
    logo: "/images/qdrant.png"
  }
];

const TalkCard = ({ talk }) => (
  <motion.div
    className="bg-white rounded-lg shadow-md p-4 lg:p-6 transition-all duration-300 hover:shadow-lg"
    whileHover={{ scale: 1.02 }}
  >
    <a href={talk.link} target="_blank" rel="noopener noreferrer" className="flex items-start justify-between text-gray-800 hover:text-blue-600 transition-colors duration-300">
      <div className="flex-1">
        <h3 className="font-bold text-lg lg:text-xl mb-2">{talk.title}</h3>
        <p className="text-sm lg:text-base text-gray-600">{talk.subtitle}</p>
      </div>
      <div className="flex flex-col items-end ml-4">
        <img src={talk.logo} alt="Event logo" className="w-12 h-12 object-contain mb-2" />
      </div>
    </a>
  </motion.div>
);

const MainBanner = () => {
  const [currentTalk, setCurrentTalk] = useState(0);

  return (
    <div id="main" className="bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50 text-gray-900 flex flex-col items-center justify-center p-6 lg:p-16 overflow-hidden w-full min-h-screen relative">
      <motion.div
        className="flex flex-col max-w-4xl p-3 relative z-10 mt-24 lg:mt-8"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <h1 className="text-4xl sm:text-5xl lg:text-6xl font-extrabold mb-4 lg:mb-6 leading-tight text-center text-gray-800">
          We Secure AI Chatbots
        </h1>
        <p className="text-lg sm:text-xl lg:text-2xl mb-6 lg:mb-8 text-gray-700 font-light leading-relaxed text-center">
          Even the most advanced AI apps have critical vulnerabilities.
          <a href="#faq" className="text-blue-600 hover:text-blue-800 transition-colors duration-300 align-super text-xs ml-1">[1]</a>
          <br />
          Our advanced AI agents secure AI apps to protect your business from financial losses and legal liabilities.
          <a href="#products" className="text-blue-600 hover:text-blue-800 transition-colors duration-300 align-super text-xs ml-1">[2]</a>
        </p>
        <div className="flex justify-center mb-12 lg:mb-16">
          <motion.a
            href="https://cal.com/sachdh/15min"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 text-white font-bold py-3 px-6 rounded-md transition duration-300 ease-in-out transform hover:scale-105 hover:-translate-y-1 inline-flex items-center justify-center text-base lg:text-lg shadow-lg"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Book a Demo
            <ChevronRight className="ml-2 h-5 w-5" />
          </motion.a>
        </div>

        <motion.div
          className="w-full"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
        >
          <h2 className="text-2xl font-bold mb-6 text-center text-gray-800">Featured Work and Talks</h2>

          <div className="relative" style={{ height: '160px' }}>
            <AnimatePresence mode="wait">
              <motion.div
                key={currentTalk}
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 0.3 }}
                className="absolute w-full"
              >
                <TalkCard talk={talks[currentTalk]} />
              </motion.div>
            </AnimatePresence>
          </div>

          <div className="flex justify-center mt-4">
            {talks.map((_, index) => (
              <button
                key={index}
                className={`w-3 h-3 rounded-full mx-1 focus:outline-none ${
                  index === currentTalk ? 'bg-blue-600' : 'bg-gray-300'
                }`}
                onClick={() => setCurrentTalk(index)}
                aria-label={`View talk ${index + 1}`}
              />
            ))}
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default MainBanner;