import Header from './components/Header';
import MainBanner from './components/MainBanner';
import Footer from './components/Footer';
import SolutionsSection from './components/SolutionsSection';
import Platform from './components/Platform';
import FAQ from './components/FAQ';
import AboutUs from './components/Team';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


const App = () => {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header className="flex-none" />
        <div className="flex-grow overflow-auto main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/platform" element={<Platform />} />
            {/* Add more routes as needed */}
          </Routes>
        </div>

      </div>
    </Router>
  );
};

const Home = () => (
  <>
    <MainBanner />
    <SolutionsSection />
    <FAQ />
    <AboutUs />
    <Footer className="mt-auto" />
  </>
);


export default App;

/*
Header (including navigation with blog link)
Headline: "We secure AI chatbots"
Subheadline: "Even the most advanced AI chatbots have critical vulnerabilities. Our advanced AI agents secure chatbots to protect your business from financial losses and legal liabilities."
Primary CTA (Book Demo)
Social Proof & Trust Signals

Customer logos (if available)
Brief, impactful testimonials
Major Appearances / Talks from CEO (including the one you plan to add)


Products/Solutions

Brief overview of your main offerings
Key benefits for businesses


FAQ Section

Address common questions and objections
Can indirectly convey some of the "Why Choose Us" information


About Us

Company background
Team expertise
Vision and mission


Final CTA (Book Demo)
Footer (including contact information)

Two blogs - Why insecure, Notion AI, Indirect Prompt Injections
*/