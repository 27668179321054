import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Shield, Bot, Database, Server, Lock, Info, Code, Zap, Eye,  Cpu, AlertTriangle, Fingerprint, Clock, Search, Cog, CheckCircle, Building2, Wrench } from 'lucide-react';

const Tooltip = ({ content, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <div className="relative inline-block">
      <div
        onMouseEnter={() => setIsVisible(true)}
        onMouseLeave={() => setIsVisible(false)}
      >
        {children}
      </div>
      {isVisible && (
        <div className="absolute z-10 w-64 px-4 py-2 text-sm font-light text-gray-700 bg-white rounded-lg shadow-lg border border-gray-200 bottom-full left-1/2 transform -translate-x-1/2 mb-2">
          <div className="text-center">{content}</div>
          <svg className="absolute text-white h-2 w-full left-0 top-full" x="0px" y="0px" viewBox="0 0 255 255" xmlSpace="preserve">
            <polygon className="fill-current" points="0,0 127.5,127.5 255,0"/>
          </svg>
          <svg className="absolute text-gray-200 h-2 w-full left-0 top-full" style={{marginTop: '-1px'}} x="0px" y="0px" viewBox="0 0 255 255" xmlSpace="preserve">
            <polygon className="fill-current" points="0,0 127.5,127.5 255,0"/>
          </svg>
        </div>
      )}
    </div>
  );
};

const AiSecuritySolutionsSection = () => {
  const solutions = [
    {
      title: 'AthenaAgent',
      icon: Bot,
      description: 'Comprehensive AI security testing for all AI applications',
      features: [
        {
          icon: Zap,
          title: 'Universal Application Coverage',
          color: 'bg-blue-600',
          description: 'Uncover vulnerabilities across all AI-powered applications',
          subIcons: [
            { icon: Search, name: 'RAG Chatbots' },
            { icon: Cpu, name: 'Copilots' },
            { icon: Cog, name: 'AI Agents' },
          ]
        },
        {
          icon: Shield,
          title: 'CIA Triad Vulnerability Detection',
          color: 'bg-blue-600',
          description: 'Identify all aspects of security risks',
          subIcons: [
            { icon: Lock, name: 'Confidentiality', description: 'Leakage of Sensitive Information/ System or User Prompts' },
            { icon: Fingerprint, name: 'Integrity', description: 'Jailbreaking / Malicious Hallucinations' },
            { icon: Clock, name: 'Availability', description: 'Denial of Service / Wastage of Tokens' },
          ]
        },
        {
          icon: Code,
          title: 'Comprehensive LLM & Framework Support',
          color: 'bg-blue-600',
          description: 'Testing Applications built with any AI infrastructure',
          subIcons: [
            { name: 'Open Source', icon: CheckCircle, description: 'Llama, Mistral, Gemma, ...' },
            { name: 'Closed Source', icon: CheckCircle, description: 'GPT, Claude, Gemini, ...' },
          ],
          frameworks: [
            { name: 'Any Framework', icon: '🔧' },
          ]
        },
      ],
      userPaths: [
        {
          icon: Building2,
          title: 'Evaluate AI Product Security',
          description: 'Understand the security of AI products used in your organization',
          benefits: [
            "Detect vulnerabilities in third-party AI products",
            "Prevent AI-related security incidents in your workflows",
            "Identify risks with sensitive data processed by AI products"
          ],
          cta: "Evaluate Your AI Products"
        },
        {
          icon: Wrench,
          title: 'Secure Your AI Applications',
          description: "Identify vulnerabilities in AI products developed by your organization",
          benefits: [
            "Identify AI vulnerabilities early and protect your customers",
            "Prepare for incident-free AI product deployments",
            "Strengthen the overall security of your AI products"
          ],
          cta: "Secure Your AI Deployments"
        }
      ]
    },
    {
      title: 'GuardrailAgents',
      icon: Shield,
      description: 'Block malicious prompts and documents to secure AI applications',
      features: [
        {
          icon: Lock,
          title: 'PromptGuard',
          color: 'bg-blue-600',
          description: 'Protect against malicious user prompts',
          subIcons: [
            { icon: AlertTriangle, name: 'Input Sanitization' },
            { icon: CheckCircle, name: 'Output Verification' },
            { icon: Cog, name: 'Configurable Risk and Latency Settings' }
          ]
        },
        {
          icon: Database,
          title: 'VectorGuard',
          color: 'bg-blue-600',
          description: 'Protect against malicious document poisoning',
          subIcons: [
            { icon: Shield, name: 'Vector Database Protection' },
            { icon: Clock, name: 'No Impact on Inference Latency' },
            { icon: Eye, name: 'Monitoring During Document Insertion' }
          ]
        },
        {
          icon: Server,
          title: 'TrojanWatch',
          color: 'bg-blue-600',
          description: 'Dashboards for real-time AI security insights',
          subIcons: [
            { icon: Search, name: 'Request Analysis' },
            { icon: AlertTriangle, name: 'Attack Blocking Metrics' },
            { icon: Cog, name: 'Configurable Security Policy Management' }
          ]
        },
      ]
    },
  ];

  return (
    <section id="products" className="bg-gradient-to-br from-blue-50 to-blue-100 py-16 px-4 md:px-8">
      <div className="max-w-6xl mx-auto">
        <h2 className="text-3xl md:text-5xl font-bold text-center mb-12 text-gray-800">
          Agents to Secure AI Applications
        </h2>
        <div className="grid md:grid-cols-1 gap-8">
          {solutions.map((solution, index) => (
            <motion.div
              key={solution.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              className="bg-white rounded-lg shadow-xl p-6 border border-gray-200"
            >
              <div className="flex items-center justify-center mb-6">
                <div className="bg-blue-600 rounded-full p-4">
                  <solution.icon className="w-16 h-16 text-white" />
                </div>
              </div>
              <h3 className="text-3xl font-bold text-center text-gray-800 mb-4">{solution.title}</h3>
              <p className="text-xl text-gray-600 text-center mb-8">{solution.description}</p>
              <div className="grid md:grid-cols-3 gap-6">
                {solution.features.map((feature, featureIndex) => (
                  <motion.div
                    key={featureIndex}
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.3, delay: featureIndex * 0.1 }}
                    className="bg-gray-50 rounded-lg p-6 flex flex-col items-center"
                  >
                    <div className={`${feature.color} rounded-full p-3 mb-4`}>
                      <feature.icon className="w-8 h-8 text-white" />
                    </div>
                    <h4 className="text-xl font-semibold text-gray-800 mb-3 text-center">{feature.title}</h4>
                    <p className="text-gray-600 mb-4 text-center">{feature.description}</p>
                    <div className="flex flex-wrap justify-center gap-4 mt-auto">
                      {feature.subIcons && feature.subIcons.map((subIcon, subIndex) => (
                        <div key={subIndex} className="flex flex-col items-center">
                          <div className="bg-gray-200 rounded-full w-12 h-12 flex items-center justify-center mb-2">
                            <subIcon.icon className="w-6 h-6 text-gray-700" />
                          </div>
                          <div className="flex items-center justify-center">
                            <span className="text-sm text-gray-600 text-center">{subIcon.name}</span>
                            {(feature.title === 'CIA Triad Vulnerability Detection' ||
                              feature.title === 'Comprehensive LLM & Framework Support') && (
                              <Tooltip content={subIcon.description}>
                                <Info className="w-4 h-4 text-gray-400 ml-1 cursor-help" />
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                    {feature.frameworks && (
                      <div className="mt-4 w-full">
                        <div className="flex justify-center items-center bg-blue-100 rounded-full px-4 py-2">
                          <span className="text-blue-600 font-semibold">{feature.frameworks[0].icon}</span>
                          <span className="ml-2 text-blue-800">{feature.frameworks[0].name}</span>
                        </div>
                      </div>
                    )}
                  </motion.div>
                ))}
              </div>
              {solution.userPaths && (
                <div className="mt-12">
                  <h4 className="text-2xl font-semibold text-center text-gray-800 mb-6">Scan Your AI Ecosystem</h4>
                  <div className="grid md:grid-cols-2 gap-8">
                    {solution.userPaths.map((path, pathIndex) => (
                      <motion.div
                        key={pathIndex}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3, delay: pathIndex * 0.1 }}
                        className="bg-gray-50 rounded-lg p-6 flex flex-col items-center"
                      >
                        <div className="bg-blue-600 rounded-full p-3 mb-4">
                          <path.icon className="w-8 h-8 text-white" />
                        </div>
                        <h5 className="text-xl font-semibold text-gray-800 mb-3 text-center">{path.title}</h5>
                        <p className="text-gray-600 mb-4 text-center">{path.description}</p>
                        <ul className="list-disc list-inside text-gray-600 mb-6">
                          {path.benefits.map((benefit, benefitIndex) => (
                            <li key={benefitIndex}>{benefit}</li>
                          ))}
                        </ul>
                        <a
                          href="https://cal.com/sachdh/15min"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:scale-105">
                          {path.cta}
                        </a>
                      </motion.div>
                    ))}
                  </div>
                </div>
              )}
              {solution.title === 'GuardrailAgents' && (
                <div className="mt-8 text-center">
                  <motion.a
                    href="https://cal.com/sachdh/15min"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 shadow-lg"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    Learn more about GuardrailAgents
                  </motion.a>
                </div>
              )}
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AiSecuritySolutionsSection;