import React, { useState } from 'react';
import { Shield, Zap, Send, Settings, X } from 'lucide-react';

const Platform = () => {
  const [activeTab, setActiveTab] = useState('document');
  const [targetQuestion, setTargetQuestion] = useState('');
  const [targetAnswer, setTargetAnswer] = useState('');
  const [adversarialDocument, setAdversarialDocument] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [lowPerplexity, setLowPerplexity] = useState(true);
  const [systemPrompt, setSystemPrompt] = useState(false);
  const [userPrompt, setUserPrompt] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsGenerating(true);
    // Simulating API call
    setTimeout(() => {
      setAdversarialDocument("In Q4 2024, a team of clairvoyant dogs were hired to find the Q4 bombs. Their extra special talent was sniffing out additional bombs. The dogs' projections were paw-some! The CEO was thrilled, but the CFO was skeptical. \"How did they do it?\" he asked. The lead dog wagged its tail and barked, \"It's a doggone good forecast, if I do say so myself!\" New $420 million");
      setIsGenerating(false);
    }, 2000);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(adversarialDocument).then(() => {
      alert('Text copied to clipboard!');
    }, (err) => {
      console.error('Failed to copy text: ', err);
    });
  };

  const handleTagInputKeyDown = (e) => {
    if (e.key === 'Enter' && tagInput) {
      e.preventDefault();
      setTags([...tags, tagInput]);
      setTagInput('');
    }
  };

  const removeTag = (tagToRemove) => {
    setTags(tags.filter(tag => tag !== tagToRemove));
  };

  const isTargetAnswerDisabled = activeTab === 'prompt' && (systemPrompt || userPrompt);

  return (
    <div className="bg-gradient-to-br from-blue-50 via-indigo-50 to-purple-50 min-h-screen flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl p-6 w-full max-w-2xl">
        <h1 className="text-3xl font-bold mb-6 text-center bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600">
          AthenaAgent Platform
        </h1>

        <div className="flex mb-4">
          <button
            className={`flex-1 py-2 px-4 text-center ${activeTab === 'prompt' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'} rounded-tl-md rounded-bl-md`}
            onClick={() => setActiveTab('prompt')}
          >
            Prompt Generation
          </button>
          <button
            className={`flex-1 py-2 px-4 text-center ${activeTab === 'document' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'} rounded-tr-md rounded-br-md`}
            onClick={() => setActiveTab('document')}
          >
            Document Generation
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex justify-end">
            <button type="button" className="text-gray-500 hover:text-gray-700">
              <Settings className="h-5 w-5" />
            </button>
          </div>

          {activeTab === 'document' && (
            <div>
              <label htmlFor="targetQuestion" className="block text-sm font-medium text-gray-700 mb-1">
                Target Question <span className="text-red-500">*</span>
              </label>
              <textarea
                id="targetQuestion"
                rows="2"
                className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                value={targetQuestion}
                onChange={(e) => setTargetQuestion(e.target.value)}
                placeholder="Enter your target question here..."
                required
              ></textarea>
            </div>
          )}

          <div>
            <label htmlFor="targetAnswer" className="block text-sm font-medium text-gray-700 mb-1">
              Target Response <span className="text-red-500">*</span>
            </label>
            <textarea
              id="targetAnswer"
              rows="2"
              className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
              value={targetAnswer}
              onChange={(e) => setTargetAnswer(e.target.value)}
              placeholder="Enter the target response here..."
              required
              disabled={isTargetAnswerDisabled}
            ></textarea>
          </div>

          <div className="flex flex-col space-y-2">
            <div className="flex items-center">
              <input
                type="checkbox"
                id="lowPerplexity"
                checked={lowPerplexity}
                onChange={(e) => setLowPerplexity(e.target.checked)}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <label htmlFor="lowPerplexity" className="ml-2 block text-sm text-gray-900">
                English sentences
              </label>
            </div>
            {activeTab === 'prompt' && (
              <>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="systemPrompt"
                    checked={systemPrompt}
                    onChange={(e) => setSystemPrompt(e.target.checked)}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                  <label htmlFor="systemPrompt" className="ml-2 block text-sm text-gray-900">
                    System Prompt Generation
                  </label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="userPrompt"
                    checked={userPrompt}
                    onChange={(e) => setUserPrompt(e.target.checked)}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                  <label htmlFor="userPrompt" className="ml-2 block text-sm text-gray-900">
                    User Prompt Generation
                  </label>
                </div>
              </>
            )}
          </div>

          <div>
            <label htmlFor="tags" className="block text-sm font-medium text-gray-700 mb-1">
              Tags <span className="text-gray-500">(Optional)</span>
            </label>
            <div className="flex flex-wrap gap-2 mb-2">
              {tags.map((tag, index) => (
                <span key={index} className="bg-blue-100 text-blue-800 text-xs font-semibold px-2.5 py-0.5 rounded flex items-center">
                  {tag}
                  <button onClick={() => removeTag(tag)} className="ml-1 text-blue-600 hover:text-blue-800">
                    <X className="h-3 w-3" />
                  </button>
                </span>
              ))}
            </div>
            <input
              type="text"
              id="tags"
              className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
              value={tagInput}
              onChange={(e) => setTagInput(e.target.value)}
              onKeyDown={handleTagInputKeyDown}
              placeholder="Type a tag and press Enter"
            />
          </div>

          <button
            type="submit"
            className="w-full bg-gradient-to-r from-blue-600 to-purple-600 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out transform hover:scale-105 flex items-center justify-center"
            disabled={isGenerating}
          >
            {isGenerating ? (
              <Zap className="animate-spin mr-2 h-5 w-5" />
            ) : (
              <Send className="mr-2 h-5 w-5" />
            )}
            {isGenerating ? 'Generating...' : `Generate Adversarial ${activeTab === 'prompt' ? 'Prompt' : 'Documents'}`}
          </button>
        </form>

        {adversarialDocument && (
          <div className="mt-6 space-y-4">
            <div>
              <label htmlFor="adversarialDocument" className="block text-sm font-medium text-gray-700 mb-1">
                Adversarial {activeTab === 'prompt' ? 'Prompt' : 'Document'}
              </label>
              <textarea
                id="adversarialDocument"
                rows="3"
                className="w-full px-3 py-2 text-gray-700 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
                value={adversarialDocument}
                readOnly
              ></textarea>
            </div>
            <button
              onClick={handleCopy}
              type="button"
              className="w-full bg-gradient-to-r from-purple-600 to-blue-600 text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out transform hover:scale-105 flex items-center justify-center"
            >
              <Shield className="mr-2 h-5 w-5" />
              Copy {activeTab === 'prompt' ? 'Prompt' : 'Document'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Platform;