import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown } from 'lucide-react';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <motion.div
      className="border-b border-gray-200 py-4"
      initial={false}
    >
      <button
        className="flex justify-between items-center w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-lg font-semibold text-gray-800">{question}</span>
        <motion.div
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.3 }}
        >
          <ChevronDown className="w-5 h-5 text-blue-600" />
        </motion.div>
      </button>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 }
            }}
            transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <div
              className="mt-2 text-gray-600 faq-answer"
              dangerouslySetInnerHTML={{ __html: answer }}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

const FAQ = () => {
  const faqs = [
    {
      question: "Do widely used AI applications have critical security vulnerabilities?",
      answer: "Yes, widely used AI applications have critical security vulnerabilities. We've demonstrated an<a href='https://sachindharashivkar.substack.com/p/indirect-prompt-injection-with-notion' target='_blank' rel='noopener noreferrer'> Indirect Prompt Injection vulnerability in Notion AI</a>, where a poisoned document in the workspace causes deliberate hallucinations. Similar issues have been found in Microsoft Copilot and Slack by other researchers. We've also reported vulnerabilities in public-facing chatbots of several billion-dollar companies. These findings highlight the need for robust security measures in AI applications to prevent potential misuse or manipulation."
    },
    {
      question: "How can you use AthenaAgent and/or GuardrailAgents?",
      answer: "We offer AthenaAgent for automated red teaming, complemented by human experts to find vulnerabilities in your AI applications. GuardrailAgents protect against malicious prompts and document poisoning. Currently, we work closely with customers to tailor these products to their security needs. While we're developing self-serve options for the early next year, our focus now is on ensuring early customer success. To see how these tools can secure your products, <a href='https://cal.com/sachdh/15min' target='_blank' rel='noopener noreferrer'>book a demo</a> for a firsthand look."
    },
    {
      question: "How does the pricing work?",
      answer: "Our pricing is flexible and tailored to your security needs. AthenaAgent contracts consider factors like black box vs. white box testing, application complexity, and risk profile. We offer annual contracts with options for fixed or variable number of security scans. For GuardrailAgents, we factor in latency requirements, deployment preferences (cloud or on-premise), and request volume, with annual contracts that include a base price plus usage-based charges. As we're committed to early customer success, we're open to discussing custom pricing that best fits your requirenments."
    }
  ];

  return (
    <section id="faq" className="bg-indigo-50 py-16 px-4 md:px-8">
      <div className="max-w-3xl mx-auto">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-8 text-gray-800">
          Frequently Asked Questions
        </h2>
        <div className="bg-white rounded-lg shadow-xl p-6 border border-gray-200">
          {faqs.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </div>
            <style jsx>{`
        .faq-answer a {
          color: #3B82F6;
          text-decoration: underline;
          font-weight: 500;
          transition: color 0.3s ease;
        }
        .faq-answer a:hover {
          color: #2563EB;
        }
      `}</style>
    </section>
  );
};

export default FAQ;